import React from 'react'
import { graphql } from 'gatsby'
import BlogList from 'components/BlogPage/BlogList'
import Header from 'components/Header'
import Footer from 'components/Footer'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import { Location, ContentfulBlogList, ContentfulBlogListEntry } from 'shared/types'
import { genericTitle, genericdescription, baseUrl, homeImage } from 'shared/constants'
import { BLOG_LIST } from 'shared/routes'
import { mapToBlogListFields, mapToGeneralPageFields } from 'services'

interface Blog {
  location: Location
  data: ContentfulBlogList
}

const Blog = ({ location, data }: Blog): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const blogPosts = data.allContentfulBlog.nodes.map((node: ContentfulBlogListEntry) => mapToBlogListFields(node))
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${BLOG_LIST}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <BlogList blogPosts={blogPosts} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query BlogList($locale: String) {
    allContentfulBlog(filter: { node_locale: { eq: $locale } }, sort: { order: DESC, fields: blogPublishDate }) {
      nodes {
        blogImages {
          fixed(width: 1080) {
            src
          }
        }
        blogSummary {
          raw
        }
        blogTitle
        blogAuthor
        contentful_id
        blogPublishDate
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Blog
