import React, { useState } from 'react'
import { Row, Container, Card, CardBody, CardTitle } from 'reactstrap'
import ArticleSection from 'components/BlogPage/ArticleSection'
import { BlogPostSummary } from 'shared/types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import PaginationSection from './PaginatedSection'

interface BlogPage {
  blogPosts: BlogPostSummary[]
}

const BlogPage = ({ blogPosts }: BlogPage): JSX.Element => {
  const itemsPerPage = 12
  const pageCount = Math.ceil(blogPosts.length / itemsPerPage)
  const [currentItems, setCurrentItems] = useState(blogPosts.slice(0, itemsPerPage))

  const handlePageClick = (pageNumber: number) => {
    const newOffset = (pageNumber * itemsPerPage) % blogPosts.length
    setCurrentItems(blogPosts.slice(newOffset, newOffset + itemsPerPage))
  }

  return (
    <div className="main">
      <div className="section text-center top-of-page">
        <Container className="title">
          <h2 className="title">
            <FormattedMessage id="blog_title" />
          </h2>
        </Container>
        <Container>
          {blogPosts.length > 0 ? (
            <Container>
              <Row>
                {currentItems.map((blog) => (
                  <ArticleSection
                    key={blog.id}
                    id={blog.id}
                    title={blog.title}
                    author={blog.author}
                    date={blog.date}
                    summary={blog.summary}
                    image={blog.imageUrls[0]}
                  />
                ))}
              </Row>
              <PaginationSection pageCount={pageCount} onClick={handlePageClick} />
            </Container>
          ) : (
            <Container>
              <div className="ml-auto mr-auto col-md-8">
                <Card className="card-blog card-plain text-center">
                  <CardBody>
                    <CardTitle>
                      <h3>Aucune publication n&apos;a été trouvée.</h3>
                    </CardTitle>
                  </CardBody>
                </Card>
              </div>
            </Container>
          )}
        </Container>
      </div>
    </div>
  )
}

export default BlogPage
