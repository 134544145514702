import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

interface PaginationSection {
  onClick: (index: number) => void
  pageCount: number
}

const PaginationSection = ({ pageCount, onClick }: PaginationSection): JSX.Element => {
  const paginatedItems = []

  for (let i = 0; i < pageCount; i++) {
    paginatedItems.push(
      <PaginationItem key={i + 1}>
        <PaginationLink href="#" onClick={() => onClick(i)}>
          {i + 1}
        </PaginationLink>
      </PaginationItem>,
    )
  }

  return (
    <div style={{ display: 'block', padding: 30 }}>
      <Pagination style={{ justifyContent: 'center' }}>
        <PaginationItem>
          <PaginationLink first disabled href="#" onClick={() => onClick(0)} />
        </PaginationItem>
        {paginatedItems}
        <PaginationItem>
          <PaginationLink last disabled href="#" onClick={() => onClick(pageCount - 1)} />
        </PaginationItem>
      </Pagination>
    </div>
  )
}

export default PaginationSection
