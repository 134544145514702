import React from 'react'
import { Container, Card, CardImg, CardTitle, CardText, CardBody, Col } from 'reactstrap'
import { navigate } from 'gatsby'
import { convertToSlug } from 'utils/functions'
import { BLOG_LIST } from 'shared/routes'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

interface ArticleSection {
  id: string
  title: string
  author: string
  date: string
  summary: React.ReactNode
  image?: string
}

const ArticleSection = ({ id, title, author, date, summary, image }: ArticleSection): JSX.Element => {
  const language = useIntl().locale || 'fr'
  const urlWithLocale =
    language === 'en' ? `/${language}${BLOG_LIST}/${convertToSlug(title)}` : `${BLOG_LIST}/${convertToSlug(title)}`
  return (
    <Col md="6" lg="4" id={id}>
      <Container
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate(urlWithLocale)
        }}
      >
        <div className="ml-auto mr-auto col-md-16">
          <Card className="card-blog card-plain text-center">
            {image && <CardImg top className="img-blog lazyload" data-src={image} alt={title} />}
            <CardBody>
              <CardTitle tag="div">
                <a className="btn btn-link" href={urlWithLocale}>
                  <h4 className="mt-0">{title}</h4>
                </a>
                <h5 className="title-uppercase mt-0">
                  <small>
                    <FormattedMessage id="blog_by" /> {author}
                  </small>
                </h5>
                <h6 className="title-uppercase mb-3">{date}</h6>
              </CardTitle>
              <CardText tag="div">{summary}</CardText>
            </CardBody>
          </Card>
        </div>
        <hr />
      </Container>
    </Col>
  )
}

export default ArticleSection
