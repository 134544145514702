import slugify from 'slugify'

export function convertToSlug(text: string): string {
  let slug = slugify(text, {
    lower: true,
    strict: true,
  })
  const lastChar = slug.slice(-1)
  slug = lastChar === '-' ? slug.slice(0, -1) : slug
  return slug
}
